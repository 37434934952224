@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

.is-to-load-in {
	opacity: 0;
}

.is-loaded {
	opacity: 1;
	transition: opacity 0.4s;
}

// I've decided to add !important to these, if you use them the intention is to move the text to the side you want.
// Therefore, i think ensuring they always work is a higher priority than keeping the natural CSS hierachy.

.text {
	&-small {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}

	&-center {
		text-align: center !important;
	}

	&-left {
		text-align: left !important;
	}

	&-right {
		text-align: right !important;
	}

	&-left-tablet {
		@include tablet() {
			text-align: left !important;
		}
	}

	&-right-tablet {
		@include tablet() {
			text-align: right !important;
		}
	}
}

.center {
	margin-left: auto;
	margin-right: auto;
}

.full-width {
	width: 100%;
}

.relative {
	position: relative;
}

.iframer {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding-top: 56.25%;
	margin-bottom: $gutter;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border: 0;
	}
}

// Accessibility helper, used to hide an element visually without hiding it from a screen reader.
.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}
