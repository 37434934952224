@use "@/utils/variables" as *;
@use "@/utils/mixins" as *;

* {
	background-color: transparent;
	border: 0;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	background-color: $color-nearly-lightest;
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	text-rendering: optimizeLegibility;
	height: 100%;
	width: 100%;
	overflow-y: scroll;
	//scroll-behavior: smooth;

	&[class*="lockScroll_"] {
		overflow-y: hidden !important;
	}
}

input,
select,
textarea,
button {
	color: inherit;
	display: inline-block;
	-webkit-border-radius: 0;
	font: inherit;
	&[disabled] {
		cursor: not-allowed;
	}
}

button {
	cursor: pointer;
	-webkit-appearance: none;
	-moz-appearance: none;
}

iframe {
	max-width: 100%;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
	th,
	td {
		text-align: left;
	}
}

@mixin Spacings {
	&.bottom {
		margin-bottom: $gutter !important;
	}

	&.narrow-bottom {
		margin-bottom: $gutter * 0.5 !important;
	}

	&.tiny-bottom {
		margin-bottom: $gutter * 0.25 !important;
	}

	&.medium-bottom {
		margin-bottom: $gutter * 0.75 !important;
	}

	&.large-bottom {
		margin-bottom: $gutter * 1.5 !important;
	}

	&.massive-bottom {
		margin-bottom: $gutter * 2 !important;
	}

	&.gigantic-bottom {
		margin-bottom: $gutter * 2.5 !important;
	}

	&.no-bottom {
		margin-bottom: 0 !important;
	}

	&.top-spacing {
		margin-top: $gutter !important;
	}

	&.narrow-top {
		margin-top: $gutter * 0.5 !important;
	}

	&.no-top {
		margin-top: 0 !important;
	}

	&.left-spacing {
		margin-left: $gutter !important;
	}

	&.right-spacing {
		margin-right: $gutter !important;
	}

	&.right-spacing-narrow {
		margin-right: $gutter * 0.5 !important;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
hr,
ul,
ol,
dl,
address {
	margin-bottom: $gutter;

	@include Spacings();
}

* {
	@include Spacings();
}

a[href^="tel"] {
	color: inherit;
	text-decoration: none;
	font-family: inherit;
	font-size: inherit;
	font-style: inherit;
}
